/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('assets/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('assets/roboto-v20-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/roboto-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('assets/roboto-v20-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('assets/roboto-v20-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('assets/roboto-v20-latin-regular.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
