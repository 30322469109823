/* You can add global styles to this file, and also import other style files */

/* autoprefixer grid: on */

@use '~@angular/material' as mat;

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import '~material-design-icons/iconfont/material-icons.css';
@import 'font-roboto';
@import 'font-sourcesanspro';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'OpenSans-regular', sans-serif;
}

// Plus imports for other components in your app.

// http://mcg.mbitson.com/#!?mcgpalette0=%2328206a&themename=mcgtheme
$md-mcgpalette0: (
  50: #e5e4ed,
  100: #bfbcd2,
  200: #9490b5,
  300: #696397,
  400: #484180,
  500: #28206a,
  600: #241c62,
  700: #1e1857,
  800: #18134d,
  900: #0f0b3c,
  A100: #7c75ff,
  A200: #4c42ff,
  A400: #1b0fff,
  A700: #0c00f5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

// http://mcg.mbitson.com/#!?mcgpalette1=%23333333&themename=mcgtheme
$md-mcgpalette1: (
  50: #e7e7e7,
  100: #c2c2c2,
  200: #999999,
  300: #707070,
  400: #525252,
  500: #333333,
  600: #2e2e2e,
  700: #272727,
  800: #202020,
  900: #141414,
  A100: #ef6e6e,
  A200: #ea4040,
  A400: #f60000,
  A700: #dd0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.define-palette($md-mcgpalette0);
$candy-app-accent: mat.define-palette($md-mcgpalette1, 100, 50, 900);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

$custom-typography: mat.define-typography-config(
  $font-family: 'Source Sans Pro',
  $display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline: mat.define-typography-level(24px, 32px, 400),
  $title: mat.define-typography-level(20px, 32px, 500),
  $subheading-2: mat.define-typography-level(16px, 28px, 400),
  $subheading-1: mat.define-typography-level(15px, 24px, 400),
  $body-2: mat.define-typography-level(16px, 24px, 500),
  $body-1: mat.define-typography-level(16px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(16px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat.define-typography-level(inherit, 1.125, 400)
);

@include mat.typography-hierarchy($custom-typography);
@include mat.checkbox-typography($custom-typography);

@include mat.all-component-typographies($custom-typography);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px #f5f3f2 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.mat-dialog-container-no-padding .mat-dialog-container {
  padding: 0px !important;
}

.mat-form-field {
  width: 100% !important;
  max-width: 400px;

  .mat-form-field-flex {
    background-color: white !important;
  }

  .mat-form-field-infix {
    width: 100px;
  }
}

.mat-form-field-readonly .mat-form-field-flex {
  background-color: #e9e7e6 !important;
  pointer-events: none;
}

.mat-form-field-readonly-blue .mat-form-field-flex {
  background-color: #dcdde8 !important;
  pointer-events: none;
}

.mat-table {
  border-spacing: 4px !important;
  background: transparent;

  .row-even {
    background-color: #f0eff3;
  }
  .row-odd {
    background-color: white;
  }

  .mat-header-cell,
  .mat-sort-header {
    background-color: #312c5b;
    color: white;
    border-radius: 5px;
    padding: 0 10px 0 10px !important;
  }

  td {
    border-radius: 5px;
    padding: 0 10px 0 10px !important;
  }

  .mat-sort-header-arrow {
    color: white;
  }
}

.mat-form-field.stretch-height,
.mat-form-field.stretch-height .mat-form-field-flex,
.mat-form-field.stretch-height textarea {
  height: 100%;
}
.mat-form-field.stretch-height .mat-form-field-wrapper {
  height: calc(100% - 1.34375em);
}
.mat-form-field.stretch-height .mat-form-field-infix {
  height: calc(100% - 1.34375em - 2px);
}

.background-disabled {
  background: linear-gradient(
    45deg,
    transparent 25%,
    #00000011 25%,
    #00000011 50%,
    transparent 50%,
    transparent 75%,
    #00000011 75%
  );
  background-size: 40px 40px;
}

.grid-3col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 25px;
}

.grid-4col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 25px;
}

.grid-5col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 25px;
}

.mat-h2 {
  color: rgb(28, 34, 102);
}

/* NgxPopper */

.angular-popper.visible {
  background: #e5e4ed;
  padding: 5px;
  width: auto;
}

.popper-demande-search-relance div.angular-popper.visible {
  top: -33px !important;
  left: -40px !important;
}

.popper-declaration-search-relance div.angular-popper.visible {
  top: -33px !important;
  left: -35px !important;
}

.popper-search-courrier div.angular-popper.visible {
  top: -34px !important;
  left: -51px !important;
}

.angular-popper .angular-popper__arrow {
  display: none !important;
}

.mat-tooltip {
  font-size: 14px;
}

.std-content {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.floating-menu {
  ul {
    list-style-type: none;
    margin-left: 20px;
    padding-left: 0;
    li {
      cursor: pointer;
      margin-bottom: 6px;
      color: #28206a;
    }
  }
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  height: auto !important;
}

.fc-daygrid-day.fc-day.fc-day-sat,
.fc-daygrid-day.fc-day.fc-day-sun {
  background-color: #f8f8f8;
}

.fc-daygrid-day td,
.fc-daygrid-day th {
  border: 1px solid #ccc !important;
}

.bs-datepicker-container {
  padding: 0px !important;
}
