/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('assets/source-sans-pro-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('assets/source-sans-pro-v14-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/source-sans-pro-v14-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('assets/source-sans-pro-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('assets/source-sans-pro-v14-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('assets/source-sans-pro-v14-latin-regular.svg#SourceSansPro')
      format('svg'); /* Legacy iOS */
}
